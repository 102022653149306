

.miniconf-talk-countdown {
    color: rebeccapurple;
    padding-top: 200px;
    font-size: 20.6vw;
}

.warning {
    color: orange;
}

.danger {
    color: red;
}
